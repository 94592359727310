<template>
  <!-- 招聘人员 -->
  <div class="staff" :class="{'classBorder':isBorder}">
    <div class="staff_info flex">
      <!-- v-if="item.video.length>0" -->
      <template>
        <div class="staff_video" @click.capture="play" v-on:mouseenter="MouseOver" v-on:mouseout="MouseOut">
          <video class="staff_img" ref="video" :controls="false" :poster="item.video[0].video_photo">
            <source :src="item.video[0].video_url[0]" type="video/mp4">
          </video>
          <!-- 遮罩层 -->
          <!-- <div class="mask" v-if="isMask">
            <i class=" el-icon-video-play"></i>
          </div> -->
        </div>
      </template>
      <div class="staff_info_right" @click="jumpRY(item)">
        <div class="staff_name flex_aling_center">
          <span> {{item.real_name}}</span>
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            视觉年龄：
          </div>
          {{item.note.begin_age}}- {{item.note.end_age}}岁
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            性别:
          </div>
          {{item.note.sex==1?'女':'男'}}
        </div>
        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            可接受角色类型:
          </div>

          <span v-for="(value) in item.can_person_attr">{{ value }}</span>
        </div>

        <div class="staff_text  flex_aling_center">
          <div class="staff_text_label">
            擅长角色类型：
          </div>
          <!-- 风格类型 记得更改 -->
          <span v-for="(value) in item.person_attr">{{ value }}</span>
        </div>
      </div>
      <div class="dataImagesList">
        <div class="dataImagesList_item" v-if="item.all_choose_url[0]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[0]" :preview-src-list="[item.all_choose_url[0]]">
          </el-image>
        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[1]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[1]" :preview-src-list="[item.all_choose_url[1]]">
          </el-image>
        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[2]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[2]" :preview-src-list="[item.all_choose_url[2]]">
          </el-image>

        </div>
        <div class="dataImagesList_item" v-if="item.all_choose_url[3]">
          <el-image class="dataImagesList_item_img" :src="item.all_choose_url[3]" :preview-src-list="[item.all_choose_url[3]]">
          </el-image>
        </div>
      </div>
    </div>
    <div class="staff_c  tow_ellipsis">
      <img src="@/assets/image/image/yuangong.png" alt="">
      <span>代表作：</span>
      <span class="work_name" v-for="(value,i) in item.work" :key="i">《{{value.work_name}}》</span>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      isMask: false,
    };
  },
  created() {
    console.log(this.item, "item");
  },
  methods: {
    MouseOver() {
      this.isMask = true;
      this.$refs.video.play();
    },
    MouseOut() {
      this.$refs.video.pause();
      this.isMask = false;
    },
    play() {
      if (this.isMask) {
        console.log(this.$refs.video);

        this.$refs.video.play();
      }
    },
    jumpRY(item) {
      this.$router.push({
        path: "/notesDetials",
        query: { item: JSON.stringify(item) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.staff {
  width: 100%;
  height: 280px;
  // background: #ffffff;
  background: #fff;
  border-radius: 18px;
  padding: 26px 19px;
  .staff_info {
    .staff_img {
      width: 150px;
      height: 200px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      object-fit: cover;
    }
    .staff_info_right {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 170px;
      .staff_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 20px;
          color: #f43a47;
        }
      }
      .staff_tag {
        .staff_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .staff_text {
        font-size: 12px;
        color: #000;
        .staff_text_label {
          color: #666666;
        }
      }
    }
  }
  .staff_c {
    margin-top: 15px;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 25px;
    span {
      color: #666666;
    }
    img {
      width: 17px;
      height: 15px;
      margin-right: 10px;
    }
  }
}
.classBorder {
  border: 1px solid #eae8e8;
}

// 视频
.staff_video {
  margin-right: 10px;

  position: relative;
}
.dataImagesList {
  width: 230px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  .dataImagesList_item_img {
    margin: 10px;
    width: 50px;
    height: 50px;
  }
}
</style>

