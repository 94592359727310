import router from '@/router/index'
import { Message } from 'element-ui';
import store from '@/store/index.js'
import {
  update_talk,
  update_name,
  update_msg
} from '@/utils/network.js'
export const routerGo = (path, value) => {
  router.push({ path: path, query: value })

}
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}


// 文件上传
// export const uploadFile = () => {


//   return

// }



export const updateMyProfile = (avatar) => {
  let options = {
    SDKAppID: 1600051535,
    userID: uni.getStorageSync('member_id'),
    userSig: uni.getStorageSync('userSig')
  };
  let chat = TencentCloudChat.create(options);
  let promise = chat.updateMyProfile({

    avatar: avatar,
  });
  promise.then(function (imResponse) { }).catch(function (imError) { });
}
// 每句发话都调用此接口
export const updateTalk = async (data) => {
  const res = await update_talk(data)
}

// 聊天前调一下双方的昵称更新
export const updateName = async (data) => {
  const res = await update_name(data)
}
// 修改聊天记录 获取手机号 和 试戏使用
export const updateMsg = async (data) => {
  const res = await update_msg(data)
}





export const messageError = () => {
  return Message.error({
    message: '您已申请'
  })
}


export const isAuth = () => {
  if (!store.state.CertificationStatus) {
    Message.error({
      message: '请先认证'
    })
    return true;
  }

}

export const isKX = (arr) => {
  let timer = new Date(Date.now());
  let m = timer.getTime();
  parseTime(m, "{y}-{m}-{d}");
  console.log(arr, 'arrarrarr');

  // console.log(arr.includes(parseTime), 'arr.includes(parseTime)');

  return arr.includes(parseTime) ? "暂无档期" : "档期没有"

}




/**
 * 脱敏手机号码
 *
 * @param content 待脱敏的手机号内容
 * @param fillChar 脱敏填充字符，默认为 "*"
 * @returns 脱敏后的手机号字符串
 */
export const des_phone = (content, fillChar = "*") => {
  if (!content) {
    return "";
  }

  // 非字符串转换为字符串
  content = content.toString();

  if (content.length < 11) {
    return content;
  }

  let index = 1;
  let result = "";

  for (let char of content) {
    if (index < 4 || index > content.length - 4) {
      result += char;
    } else {
      result += fillChar;
    }
    index++;
  }
  return result;
}
