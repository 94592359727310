import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import PageHd from '@/components/PageHd.vue'
import PageBt from '@/components/PageBt.vue'


import SearchInput from '@/components/searchInput/index.vue'
import JobRecrui from '@/components/JobRecrui/index.vue'
import FilterMenu from '@/components/FilterMenu/index.vue'
import Work from '@/components/work/index.vue'
import staff from '@/components/staff/index.vue'
import calendar from '@/components/calendar/index.vue'
import Member from '@/components/member/index.vue'
import MemberCompany from '@/components/member/MemberCompany.vue'
import memberNotes from '@/components/memberNotes/index.vue'
import './utils/rem.js'; //在main.js中引入rem.js
import './utils/style.css'
import '@/router/permission'

import { routerGo } from '@/utils/index'
// 高德地图配置

import { updateMyProfile, updateTalk, updateName, isKX, parseTime, des_phone } from '@/utils/index'

Vue.component('SearchInput', SearchInput)
Vue.component('JobRecrui', JobRecrui)
Vue.component('FilterMenu', FilterMenu)
Vue.component('staff', staff)
Vue.component('Work', Work)
Vue.component('Member', Member)
Vue.component('Calendar', calendar)
Vue.config.productionTip = false
Vue.component('PageHd', PageHd)
Vue.component('PageBt', PageBt)
Vue.component('memberNotes', memberNotes)
Vue.component('MemberCompany', MemberCompany)


Vue.prototype.$updateMyProfile = updateMyProfile
Vue.prototype.$updateTalk = updateTalk   //  每句发话都调用此接口
Vue.prototype.$updateName = updateName   //  聊天前调一下双方的昵称更新
Vue.prototype.$isKX = isKX   //  聊天前调一下双方的昵称更新
Vue.prototype.$desPhone = des_phone


// VueAMap.initAMapApiLoader({ // 初始化插件
//   key: 'c476a9233f0a10993d8c8d111f24cf2e', // 高德key
//   plugin: ['AMap.Geolocation', 'AMap.CitySearch', 'AMap.Geocoder'], // 插件集合，根据自己的需求添加
//   uiVersion: '1.0.11', // 不加会报错，加上吧
//   v: '1.4.15' // 默认高德 sdk 版本为 1.4.4
// })

// 申请的Web端（JS API）的需要写上下面这段话
//   (window as any)._AMapSecurityConfig = {
//   securityJsCode: 'baedc1754b1981022b1565fb9cc6f36b' // 高德Web端安全密钥
// }


Vue.prototype.$routerGo = routerGo
Vue.prototype.$parseTime = parseTime

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


