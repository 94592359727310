<template>
  <!-- 作品经历 -->
  <div class="work pointer">
    <div class="work_info flex">
      <img class="work_img" :src="item.photo" alt="">
      <div class="work_info_right">
        <div class="work_name flex_js_sb flex_aling_center">
          <span>{{ item.past_name }} </span>
        </div>
        <div class="work_text  flex_aling_center">
          <div class="work_text_label">
            剧目风格：
          </div>
          {{ item.past_attr }}
        </div>
        <div class="work_text  flex_aling_center">
          <div class="work_text_label">
            剧目类型：
          </div>
          {{ item.past_type }}
        </div>
        <div class="work_text  flex_aling_center">
          <div class="work_text_label">
            集数：
          </div>
          {{ item.past_count }}
        </div>
        <div class="work_text  flex_aling_center">
          <div class="work_text_label">
            导演：
          </div>
          {{ item.director }}
        </div>
        <div class="work_text  flex_aling_center">
          <div class="work_text_label">
            编剧：
          </div>
          {{ item.scriptwriter }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped>
.work {
  padding: 29px 20px;
  width: 400px;

  // background: pink;
  background: #fff;
  border: 1px solid #eae8e8;
  border-radius: 18px;
  .work_info {
    .work_img {
      width: 93px;
      height: 129px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .work_info_right {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .work_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        margin-bottom: 5px;
        div {
          font-size: 18px;
          color: #f43a47;
        }
      }
      .work_tag {
        .work_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .work_text {
        font-size: 16px;
        color: #000;

        .work_text_label {
          color: #666666;
        }
      }
    }
  }
  .work_c {
    font-weight: 500;
    font-size: 16px;
    color: #666666;
    line-height: 26px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f4f6f9;
    margin: 10px 0;
  }
  .work_company {
    img {
      width: 29px;
      height: 28px;
      border-radius: 50%;
      margin-right: 10px;
    }

    font-weight: 500;
    font-size: 16px;
    color: #333333;
  }

  .work_company_name {
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
    }

    font-size: 16px;
    color: #999999;
  }
}
</style>

