<template>
  <div class="home">
    <headerTab></headerTab>
    <div class="pageView">
      <!-- <img src="@/assets/login.png" alt=""> -->
      <div class="loginForm">
        <div class="lopgin_title">用户登录</div>
        <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-editForm" label-width="100px" label-position="left" size="mini">

          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input type="password" v-model="form.password" placeholder="请输入"></el-input>
          </el-form-item>

        </el-form>

        <div class="submit  flex_al_js_center " @click="login">立即登录</div>
        <div class="login_select">
          <span @click="$routerGo('/reg')">用户注册</span>
          <span @click="$routerGo('/forget')">忘记密码</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Userlogin, getUser } from "@/utils/network.js";
export default {
  data() {
    return {
      form: {},
      rules: {
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        password: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  methods: {
    login() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            ...this.form,
          };
          Userlogin(data).then((res) => {
            if (res.code == 200) {
              localStorage.setItem("toKeData", JSON.stringify(res.data));
              localStorage.setItem("member_id", res.data.member_id);
              this.getUserinfo(res.data.member_id);
            }
          });
        } else {
          return false;
        }
      });
    },
    async getUserinfo(member_id) {
      const res = await getUser({
        member_id,
      });
      this.$message({
        message: "登录成功",
        type: "success",
      });

      localStorage.setItem("userInfo", JSON.stringify(res.data));
      this.$router.replace("/");
    },
  },
};
</script>

<style  scoped lang="scss">
.pageView {
  position: relative;
  max-width: 100vw;
  min-height: 95vh;
  max-height: 95vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.loginForm {
  padding: 50px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 470px;

  z-index: 9;
  background: #ffffff;
}
.login_select {
  padding: 0 15px;
  margin-top: 30px;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}
</style>