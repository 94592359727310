<template>
  <!-- 个人资料的编辑 -->
  <div class="Resume_right_c">

    <div class="imglist">
      <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-success="handleAvatarSuccess">
        <img class="avater" :src="form.avatar" alt="">
        <img class="photo" src="@/assets/image/mine/photo.png" alt="">
      </el-upload>
    </div>
    <div class="form">
      <el-row :gutter="20">
        <el-form label-position="top" class="demo-form-inline" size="mini" ref="ruleForm" :rules="rules" :model="form">
          <el-col :span="12">
            <el-form-item label="姓名" prop="real_name">
              <el-input v-model="form.real_name" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select style="width:100%" v-model="form.sex" placeholder="请选择">
                <el-option label="女" :value="1">
                </el-option>
                <el-option label="男" :value="2">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所在城市" prop="city_arr">

              <el-cascader style="width: 100%" v-model="form.city_arr" :options="options" clearable @change="handleChange" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="电话号码" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="身高" prop="height">
              <el-input v-model="form.height" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重" prop="weight">
              <el-input v-model="form.weight" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="鞋码" prop="shoe_size">
              <el-input v-model="form.shoe_size" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="三围" prop="">
              <el-input v-model="form.three_size" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="每日报价" prop="day_price">
              <el-input v-model="form.day_price" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有个人抖音号" prop="">
              <el-select style="width:100%" v-model="form.is_person_douyin" placeholder="请选择">
                <el-option label="是" :value="1">
                </el-option>
                <el-option label="否" :value="0">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.is_person_douyin==1">
            <el-form-item label="抖音号" prop="douyin">
              <el-input v-model="form.douyin" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.is_person_douyin==1">
            <el-form-item label="粉丝量级" prop="fans_count">
              <el-input type="number" v-model="form.fans_count" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="薪资面议" prop="day_price_mian">
              <el-select style="width:100%" v-model="form.day_price_mian" placeholder="请选择">
                <el-option label="是" :value="1">
                </el-option>
                <el-option label="否" :value="2">
                </el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="籍贯" prop="city_arr1">
              <el-cascader style="width: 100%" v-model="form.city_arr1" ref="cascader" :options="options" clearable @change="handleChange1" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="擅长角色类型" prop="person_attr">
              <el-select style="width:100%" multiple v-model="form.person_attr" placeholder="请选择">
                <el-option label="主演" value="主演">
                </el-option>
                <el-option label="主要配角" value="主要配角">
                </el-option>
                <el-option label="特约" value="特约">
                </el-option>
                <el-option label="小特" value="小特">
                </el-option>
                <el-option label="前景" value="前景">
                </el-option>
                <el-option label="特型" value="特型">
                </el-option>
                <el-option label="群演" value="群演">
                </el-option>
                <el-option label="儿童演员" value="儿童演员">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="视觉年龄" prop="begin_age">
              <div class="form_item_tow">
                <el-input type="number" v-model="form.begin_age" placeholder="请输入内容"></el-input>-
                <el-input type="number" v-model="form.end_age" placeholder="请输入内容"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否有经纪公司" prop="">
              <el-select style="width:100%" v-model="form.is_borker_company" placeholder="请选择">
                <el-option label="否" :value="1">
                </el-option>
                <el-option label="是" :value="2">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- v-if="form.is_borker_company==2" -->
          <template v-if="form.is_borker_company==2">
            <el-col :span="12">
              <el-form-item label="经纪公司名称">
                <el-input v-model="form.borker_company" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="12">
            <el-form-item label="	是否有经纪人" prop="is_borker">
              <el-select style="width:100%" v-model="form.is_borker" placeholder="请选择">
                <el-option label="否" :value="1">
                </el-option>
                <el-option label="是" :value="2">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可接受角色类型" prop="can_person_attr">
              <el-select style="width:100%" multiple v-model="form.can_person_attr" placeholder="请选择">
                <el-option label="主演" value="主演">
                </el-option>
                <el-option label="主要配角" value="主要配角">
                </el-option>
                <el-option label="特约" value="特约">
                </el-option>
                <el-option label="小特" value="小特">
                </el-option>
                <el-option label="前景" value="前景">
                </el-option>
                <el-option label="特型" value="特型">
                </el-option>
                <el-option label="群演" value="群演">
                </el-option>
                <el-option label="儿童演员" value="儿童演员">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="form.is_borker==2">
            <el-col :span="12">
              <el-form-item label="经纪人电话" prop="">
                <el-input v-model="form.borker_phone" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="	经纪人名称" prop="">
                <el-input v-model="form.borker_name" placeholder="请输入内容"></el-input>
              </el-form-item>
            </el-col>

          </template>
          <el-col :span="24" v-if="form.is_borker_company==2">
            <el-form-item label="	经济公司介绍" prop="">
              <el-input type="textarea" :rows="4" v-model="form.borker_company_content" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="兴趣爱好" prop="hobby">
              <el-input type="textarea" :rows="4" v-model="form.hobby" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="个人描述" prop="content">
              <el-input type="textarea" :rows="4" v-model="form.content" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="特长描述" prop="">
              <el-input type="textarea" :rows="4" v-model="form.special" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle">
          取消
        </div>
        <div class="recruit_btn" @click="save()">
          确定
        </div>
      </div>

    </div>
  </div>
</template>

<script >
import addressData from "@/utils/addressData";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { save_note, member_note, update_info } from "@/utils/network.js";
export default {
  data() {
    return {
      form: {
        city_arr: [],
        city_arr1: [],
        real_name: "",
        sex: 1,
        avatar: "",
        person_attr: [],
        can_person_attr: [],
      },
      isShowAddress: false,
      action: process.env.VUE_APP_BASE_API + "/index/upload",
      options: addressData,
      rules: {
        real_name: [{ required: true, message: "不能为空", trigger: "blur" }],
        sex: [{ required: true, message: "不能为空", trigger: "blur" }],
        begin_age: [{ required: true, message: "不能为空", trigger: "blur" }],
        phone: [{ required: true, message: "不能为空", trigger: "blur" }],
        borker_phone: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        is_person_douyin: [
          { required: true, message: "请选择", trigger: "blur" },
        ],

        height: [{ required: true, message: "不能为空", trigger: "blur" }],
        weight: [{ required: true, message: "不能为空", trigger: "blur" }],
        shoe_size: [{ required: true, message: "不能为空", trigger: "blur" }],
        three_size: [{ required: true, message: "不能为空", trigger: "blur" }],
        day_price_mian: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        day_price: [{ required: true, message: "不能为空", trigger: "blur" }],
        city_arr: [{ required: true, message: "不能为空", trigger: "blur" }],
        city_arr1: [{ required: true, message: "不能为空", trigger: "blur" }],
        hobby: [{ required: true, message: "不能为空", trigger: "blur" }],
        content: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getinfo();
  },
  computed: {
    ...mapState(["userInfo", "authType", "currentCity"]),
  },
  mounted() {},
  methods: {
    // 这里在增加一个县级市选择
    async getinfo() {
      const res = await member_note();
      // res.data.member ? res.data.member.avatar : "";

      if (res.data.note) {
        this.form = res.data.note || {};
        this.form.city_arr = res.data.note?.city_arr || [];
        this.form.city_arr1 = res.data.note?.city_arr1 || [];

        this.form.sex = res.data.note
          ? Number(res.data.note.sex)
          : this.$set(this.form, "sex", 1);
      }
      this.$set(this.form, "avatar", this.userInfo.avatar);

      this.$forceUpdate();
    },
    handleAvatarSuccess(e) {
      this.$forceUpdate();
      let src = "https://performapi.90028.cn" + e.data;
      this.$set(this.form, "avatar", src);
    },
    // 所在地
    handleChange(val) {
      addressData.forEach((item) => {
        if (item.value == val[0]) {
          this.form.address_provice = item.label;
          item.children.forEach((cityitem) => {
            if (cityitem.value == val[1]) {
              this.form.address_city = cityitem.label;
              this.form.address_cityid = cityitem.value;
            }
          });
        }
      });
    },
    // 籍贯
    handleChange1(val) {
      addressData.forEach((item) => {
        if (item.value == val[0]) {
          this.form.place_province = item.label;
          item.children.forEach((cityitem) => {
            if (cityitem.value == val[1]) {
              this.form.place_city = cityitem.label;
              this.form.place_cityid = cityitem.value;
            }
          });
        }
      });
    },
    cencle() {
      this.$confirm("确定要退出吗, 数据不会保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("close");
        })
        .catch(() => {});
    },
    save() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.form,
          };

          const res = await save_note(data);
          const res1 = await update_info({ avatar: this.form.avatar });

          if (res.code == 200) {
            this.$emit("close");
            this.$message({
              message: "操作成功",
              type: "success",
            });
          } else {
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Resume_right_c {
  display: flex;
  .imglist {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 110px;
    height: 101px;
    margin-right: 15px;
    .avater {
      width: 101px;
      height: 101px;
      background: #333333;
    }
    .photo {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 99;
      width: 26px;
      height: 26px;
      transform: translate(-50%, -50%);
    }
  }
}
.form {
  width: 100%;
  padding: 20px;

  .form_c {
    margin-top: 20px;
    width: 370px;
  }
  .recruit_btn,
  .recruit_lebtn {
    margin: 0 auto;
    margin-top: 40px;
    width: 134px;
    height: 53px;
  }
}
.form_item_tow {
  width: 98%;
  display: flex;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.recruit_btns {
  display: flex;
  justify-content: flex-end;
}

::v-deep .el-input__inner {
  height: 44px;
  background: #f8f8f8 !important;
  border-radius: 8px;
  border: none !important;
}

::v-deep .el-upload {
  background: #f8f8f8 !important;
}
::v-deep .el-textarea__inner {
  background: #f8f8f8 !important;
  border-radius: 8px;
  border: none !important;
}
</style>
