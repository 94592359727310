<template>
  <div class="memeber_box pointer">
    <div class="setting flex_aling_center pointer" @click="jump('/accountOptions')" v-if="authType==1">
      <i class="el-icon-setting"></i>
      账户设置
    </div>
    <!-- &&userInfo.company_status !=5 && userInfo.company_status!=6   -->
    <div class="setting flex_aling_center pointer" @click="jump('/companyInfo') " v-if="authType==2">
      <i class="el-icon-setting"></i>
      账户设置
    </div>
    <!-- 人员信息 -->
    <div class="member">
      <img :src="userInfo.avatar" alt="">
      <div class="member_info flex_column_js_sa">
        <div class="member_name">
          {{userInfo.real_name}}
        </div>
        <div class="flex_aling_center" v-if="authType==1">
          <img class="gongsi" src="@/assets/image/details/ll.png" alt="" />

          <!-- {{info.note?.is_borker_company==2?info.note.borker_company:'个人演员'}} -->

        </div>
        <div class="flex_aling_center" v-if="authType==2">

          <i class="el-icon-phone-outline"></i>
          {{userInfo.phone}}
        </div>
      </div>
    </div>
    <div class="member_num flex_aling_center ">
      <div class="member_num_i flex_column_aling_center" v-if="authType==1" @click="jump()">
        <div class="num">
          {{talkdata.send_count || 0}}
        </div>
        <div>
          已投递
        </div>
      </div>
      <div class="member_num_i flex_column_aling_center" v-if="authType==2">
        <div class="num">
          {{talkdata.collection_count || 0}}
        </div>
        <div>
          感兴趣
        </div>
      </div>

      <div class="member_num_i flex_column_aling_center" v-if="authType==2">
        <div class="num">
          {{talkdata.block_count || 0}}
        </div>
        <div>
          拉黑
        </div>
      </div>
      <!-- @click="toggle(1)" -->
      <div class="member_num_i flex_column_aling_center" v-if="authType==1" @click="jump('/walkThrough',1)">
        <div class="num">
          {{talkdata.wait_through  || 0}}
        </div>
        <div>
          待试戏
        </div>
      </div>
      <!-- @click="toggle(2)" 暂时不需要 -->
      <div class="member_num_i flex_column_aling_center" v-if="authType==1" @click="jump('/walkThrough',3)">
        <div class="num">
          {{talkdata.through  || 0}}
        </div>
        <div>
          已试戏
        </div>
      </div>
    </div>
    <div class="member_line  marinT10 flex_js_sb">
      <div class="member_line_item flex_aling_center">
        <img src="@/assets/image/mine/l1.png" alt="">
        <div class="flex_column_js_sa " @click="jumpTC(5)" v-if="authType==1">
          <div class="member_title">增加明星曝光</div>
          <div class="member_text">增加更多曝光度</div>
        </div>
        <div class="flex_column_js_sa " v-else @click="jumpTC(2)">
          <div class="member_title">置顶角色</div>
          <div class="member_text">增加更多曝光度</div>
        </div>
      </div>
      <div class="member_line_item flex_aling_center" style="background:#F5F9FF">
        <img src="@/assets/image/mine/l2.png" alt="">
        <div class="flex_column_js_sa " @click="opendialogVisible" v-if="authType==1">
          <div class="member_title">档期管理</div>
          <div class="member_text">更好的时效管理</div>
        </div>
        <div class="flex_column_js_sa " v-if="authType==2" @click="PublishRole('/PostPosition')">
          <div class="member_title">发布角色</div>
          <div class="member_text">更好的时效管理</div>
        </div>
      </div>
    </div>
    <div class="notes marinT10 flex_aling_center" @click="jump('/OnlineResume')" v-if="authType==1">
      <div class="notes_btn flex_aling_justify_center">
        编辑
      </div>
      <img src="@/assets/image/mine/l3.png" alt="">
      <div class="flex_column_js_sa ">
        <div class="notes_title">在线演员模卡</div>
        <template v-if="noteAddtimer">
          <div class="notes_text" v-if="noteAddtimer">最后更新于 {{$parseTime(new Date(noteAddtimer*1000))}}</div>
        </template>
        <div class="notes_text" v-else>
          暂无在线演员模卡
        </div>
      </div>
    </div>

    <div class="notes marinT10 " v-if="authType==1">
      <input type="file" id="fileInput" accept=".pdf" @change="onFileChange">
      <div class="notes_btn  flex_aling_justify_center" @click="uploadPdf">
        上传
      </div>
      <div class="flex_aling_center" @click="pdf">
        <img src="@/assets/image/mine/l3.png" alt="">
        <div class="flex_column_js_sa ">
          <div class="notes_title">附件演员模卡</div>
          <div class="notes_text">应聘演员 {{userInfo.real_name}}</div>
        </div>
        <!-- <div class="flex_column_js_sa " @click="uploadPdf">
          <div class="notes_title">点击上传</div>
        </div> -->
      </div>
      <div class="timer flex_js_sb" v-if="pdflist.length>0">
        <span> 最后更新于 {{$parseTime(new Date(pdflist[0].add_time*1000))}} </span>
        <i class="el-icon-delete" @click="delPDF"></i>
      </div>
      <template v-else>
        <div class="timer">
          暂无附件演员模卡
        </div>
      </template>
    </div>
    <div class="option flex_js_sb flex_aling_center " v-if="authType==1" @click="jump('/record')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        收藏的角色
      </div>
      <div class="right">
        {{talkdata.collection_count}}
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- 关注的公司 -->
    <!-- <div class="option flex_js_sb flex_aling_center " v-if="authType==1" @click="jump('/record')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        关注的公司
      </div>
      <div class="right">
        {{talkdata.coll_company_count}}
        <i class="el-icon-arrow-right"></i>
      </div>
    </div> -->
    <!-- 公司    对应移动端的角色管理-->
    <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/positionManage')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        角色管理
      </div>
      <div class="right">

        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/record')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        演员模卡管理
      </div>
      <div class="right">

        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/record')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        我的收藏
      </div>
      <div class="right">

        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/CrewManagge')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        剧组管理
      </div>
      <div class="right">

        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/accountManage')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        剧组子账号管理
      </div>
      <div class="right">

        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <!-- <div class="option flex_js_sb flex_aling_center " v-if="authType==2&&userInfo.crew_id==0" @click="dialogVisibleNum=true">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        加入剧组
      </div>
      <div class="right">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <el-dialog title="加入剧组" :modal-append-to-body="false" :visible.sync="dialogVisibleNum" width="28%">
      <el-form>
        <el-form-item label="邀请码">
          <el-input v-model="num" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div class="recruit_btn" @click="saveShare">
        确定
      </div>
    </el-dialog> -->
    <!-- <div class="option flex_js_sb flex_aling_center " v-if="authType==2" @click="jump('/accountManage')">
      <div class="left flex_aling_center">
        <img src="@/assets/image/mine/l4.png" alt="">
        账号管理
      </div>
      <div class="right">
        10
        <i class="el-icon-arrow-right"></i>
      </div>
    </div> -->
    <!-- 设置档期 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="35%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-date"></i>
          设置档期
        </div>
      </div>
      <div class="Calendar_setting  flex">
        <div class="Calendar_setting_item">

          <Calendar :open="false" :textIsShow="false" :markDays="form" @onDayClick="onDayClick"></Calendar>
        </div>

      </div>

    </el-dialog>

  </div>
</template>

<script>
import { isAuth } from "@/utils/index";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  talk_data,
  member_note,
  save_usetime,
  del_usetime,
  del_note_pdf,
  save_note_pdf,
  uploadIndex,
  use_crew_code,
} from "@/utils/network";

export default {
  data() {
    return {
      talkdata: {},
      info: {},
      dialogVisible: false,
      dialogVisiblePDF: false,
      dialogVisibleNum: false,
      form: [],
      file: {},
      pdfsrc: "",
      num: "",
      pdflist: [],
      noteAddtimer: null,
    };
  },
  created() {
    this.getinfo();
    this.gettalk_data();
  },
  computed: {
    ...mapState(["company", "authType", "userInfo", "isRew"]),
  },
  methods: {
    // 发布角色
    PublishRole(path) {
      // 如果是公司
      if (this.userInfo.company_status == 3) {
        this.$message({
          message: "暂不能进行操作",
          type: "error",
        });
        return;
      }

      this.$router.push(path);
    },
    // 激活邀请码
    async saveShare() {
      let data = {
        code: this.num,
      };
      const res = await use_crew_code(data);
      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.getmember_info();
        window.location.reload();
      }
      this.dialogVisibleNum = false;
    },
    // 购买套餐类型
    jumpTC(index) {
      // 如果是剧组管理员 不能操作
      if (this.userInfo.company_status == 6) {
        this.$message({
          message: "暂不能进行操作",
          type: "error",
        });
        return;
      }
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }

      this.$router.push({ path: "/PackageBuy", query: { type: index } });
    },
    toggle(index) {
      this.$emit("toggle", index);
    },
    opendialogVisible() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      this.dialogVisible = true;
    },
    // 跳转演员模卡编辑
    jump(path, index) {
      if (
        !this.$store.state.CertificationStatus &&
        path != "/accountOptions" &&
        path != "/companyInfo"
      ) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }

      // if (this.userInfo.company_status == 3) {
      //   console.log(path);

      //   if (path == "/companyInfo" || path == "/CrewManagge") {
      //     this.$router.push(path);
      //   } else {
      //     this.$message({
      //       message: "身份权限不足",
      //       type: "error",
      //     });
      //     return;
      //   }
      // }

      if (path == "/walkThrough") {
        this.$router.push({ path, query: { index } });
        // window.location.reload();
      } else {
        this.$router.push(path);
      }
    },
    async onFileChange(e) {
      let file = e.target.files[0];
      if (file) {
        var data = new FormData();
        data.append("file", file);
        const res = await uploadIndex(data);
        let pdf = "https://performapi.90028.cn" + res.data;

        save_note_pdf({ pdf }).then((uploadRes) => {
          console.log(uploadRes);
          if (uploadRes.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getinfo();
          }
        });
      }
    },
    // 上传
    uploadPdf() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      var input = document.getElementById("fileInput");
      input.click();
    },
    // 删除附件演员模卡
    delPDF() {
      if (!this.$store.state.CertificationStatus) {
        this.$message({
          message: "请先认证",
          type: "error",
        });
        return;
      }
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await del_note_pdf({ id: this.pdflist[0].id });
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getinfo();
          }
        })
        .catch(() => {});
    },
    // 下载预览
    pdf() {
      if (this.pdflist.length > 0) {
        this.pdfsrc = this.pdflist[0].pdf;
        window.open(this.pdfsrc);
      }
    },
    // 个人中心数据 感兴趣什么的
    async gettalk_data() {
      let data = {};
      if (this.authType == 2) {
        data.company_member_id = this.company.member.member_id;
      }
      const res = await talk_data(data);
      this.talkdata = res.data;
    },
    // 个人演员模卡   z这个单独拿出来
    async getinfo() {
      let data = {};
      const res = await member_note(data);

      this.pdflist = res.data.note_pdf;
      this.noteAddtimer = res.data.note?.add_time || null;

      this.form = res.data.usetime.map((item) => item.day_time);

      this.info = res.data;
    },
    async onDayClick(e) {
      let data = {
        day_time: e.date,
      };
      let res;
      if (this.form.includes(e.date)) {
        res = await del_usetime(data);
      } else {
        res = await save_usetime(data);
      }
      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      }
      this.getinfo();
      // e.date
    },
  },
};
</script>

<style lang="scss" scoped>
.memeber_box {
  background-color: #ffff;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  .setting {
    position: absolute;
    top: 10px;
    right: 5px;

    font-size: 14px;
    color: #666666;
    .el-icon-setting {
      margin-right: 2px;
    }
  }
}
.member {
  margin-top: 23px;
  display: flex;

  img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .member_info {
    font-size: 13px;
    color: #666666;
    .member_name {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
    }
  }
}
.member_num {
  margin-top: 27px;
  justify-content: space-around;
  .member_num_i {
    font-size: 14px;
    color: #666666;
    .num {
      font-weight: bold;
      font-size: 20px;
      color: #333333;
    }
  }
}
.member_line {
  width: 100%;
  .member_line_item {
    padding: 0 3px;
    width: 47%;
    height: 63px;
    background: #fff6f5;
    border-radius: 9px;
    img {
      width: 28.8px;
      height: 28.7px;

      margin-right: 5px;
    }
    .member_title {
      font-weight: 500;
      font-size: 12px;
      color: #512525;
    }
    .member_text {
      font-size: 9px;
      color: #999999;
    }
  }
}
.notes {
  padding: 0 10px;
  background: #fbfbfb;
  border-radius: 6px;
  height: 75px;
  border: 1px solid #ecebeb;
  position: relative;
  .notes_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
    height: 18px;
    background: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #f43b48;

    font-size: 8px;
    color: #f43b48;
  }
  img {
    width: 28.8px;
    height: 28.7px;
    margin-right: 10px;
  }
  .notes_title {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
  }
  .notes_text {
    font-weight: 500;
    font-size: 12px;
    color: #999999;
  }
  .timer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #ecebeb;
    font-size: 12px;
    color: #999999;
  }
}
.option {
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  height: 60px;
  img {
    width: 21px;
    height: 17px;
    margin-right: 20px;
  }
  .right {
    font-weight: 500;
    font-size: 15px;
    color: #999999;
  }
}
.option_line {
  width: 314px;
  height: 1px;
  background: #f2f4f7;
}
.marginT40 {
  margin-top: 40px;
}
.marginT20 {
  margin-top: 20px;
}

.Calendar_setting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .Calendar_setting_item {
    // width: 48%;
  }
}
.setting_dangqi {
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  span {
    font-weight: 500;
    font-size: 15px;
    color: #ff9b0c;
  }
  .el-icon-s-help {
    font-size: 16p;
    color: #ff9b0c;
    margin-right: 5px;
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
}
.recruit_btn {
  margin: 10px auto;
  width: 150px;
  height: 48px;
  background: linear-gradient(250deg, #ef3c24, #ef7a24);
  border-radius: 11px;
}
#fileInput {
  display: none;
}

::v-deep .el-input__inner {
  width: 370px;
  height: 44px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none !important;
}
.gongsi {
  width: 15px !important;
  height: 15px !important;
  margin-right: 3px !important;
}
</style>
