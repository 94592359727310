import Vue from 'vue'
import Vuex from 'vuex'

import { getUser, talk_data, bind_tencent, company_info } from "@/utils/network.js";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
    company: localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null,
    authType: localStorage.getItem('authType') || 0,
    isRew: localStorage.getItem('isRew') || false,     // 剧组身份
    cityList: localStorage.getItem('cityList') ? JSON.parse(localStorage.getItem('cityList')) : [],
    hotList: localStorage.getItem('hotList') ? JSON.parse(localStorage.getItem('hotList')) : [],
    currentCity: localStorage.getItem('currentCity') ? JSON.parse(localStorage.getItem('currentCity')) : null,
    CertificationStatus: localStorage.getItem('CertificationStatus') || false,
  },
  getters: {
  },
  mutations: {

    SetUser(state, value) {
      state.userInfo = value
    },
    SetCompany(state, value) {
      state.company = value
    },
    SetAuthType(state, value) {
      state.authType = value
    },
    SetisRew(state, value) {
      state.isRew = value
    },
    SetisCityList(state, value) {
      state.cityList = value
      localStorage.setItem('cityList', JSON.stringify(value))
    },
    SetHotCityList(state, value) {
      state.hotList = value
      localStorage.setItem('hotList', JSON.stringify(value))
    },
    clearState(state) {
      state.userInfo = null
      state.company = null
      state.currentCity = null
      state.authType = 0
    },
    SetCurrentCity(state, value) {
      state.currentCity = value
      localStorage.setItem('currentCity', JSON.stringify(value))
    },
    SetCertificationStatus(state, value) {
      state.CertificationStatus = value
      localStorage.setItem('CertificationStatus', value)
    },
  },
  actions: {
    // 获取个人信息
    async getmember_info(context, member_id) {
      return new Promise(async (resovle, reject) => {
        let data = {
          member_id: member_id
        };
        const res = await getUser(data);
        // console.log(res.data, '获取个人信息');

        if (!res.data) {
          return
        }
        resovle(res.data || null)
        // 公司没有认证之前也是个个人
        context.commit("SetUser", res.data);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        localStorage.setItem("authType", JSON.stringify(res.data.now_login));
        let authType = res.data.now_login
        context.commit("SetAuthType", authType);
        // authType = 2  公司 1 个人
        if (authType == 2) {
          if (res.data.company_id) {
            context.dispatch('getcompany_info')
          }

          if (res.data.company_status != 1) {
            context.commit("SetCertificationStatus", true);

          }
          //  现在是剧组身份
          // 不需要认证
          // 不能购买套餐
          // 不能修改公司信息
          // 不能看见其他剧组的岗位

          // if (res.data.company_status == 5) {
          //   context.commit("SetisRew", true);

          // } else {
          //   context.commit("SetisRew", false);
          // }
        } else {
          // 个人身份      没认证
          if (res.data.member_status != 1) {
            context.commit("SetCertificationStatus", true);
          }


        }
        if (member_id) {
          const resTim = await bind_tencent({
            member_id
          });
          // 这个是存的 im 的sing 用于更改头像的操作
          if (resTim.code == 200) {
            localStorage.setItem("userSig", resTim.data);
          }
        }

      })

    },
    // 如果当前身份是 公司 则获取公司信息
    async getcompany_info(context) {
      let data = {
        company_id: context.state.userInfo.company_id
      };
      const res = await company_info(data);
      this.company = res.data || "";
      context.commit("SetCompany", res.data);
      localStorage.setItem("company", JSON.stringify(res.data));

    },
  },
  getters: {
    isUserLogin(state) {
      return state.userInfo == null

    }
  },
  modules: {
  },
})
