<template>
  <!-- 视频上传 -->
  <div class="flex uploadWrapper">

    <template v-if="tempUrl.length>0">
      <div class="image-preview flex" v-for="(item,index) in tempUrl" :key="index">
        <div class="image-preview-wrapper">
          <video controls :src="item">

          </video>
        </div>
        <div class="image-preview-action">
          <i class="el-icon-delete" @click="rmImage(index)" />
        </div>
      </div>
    </template>
    <div class="upload">
      <el-upload :accept="accept" :show-file-list="false" :file-list="filelist" :on-remove="handleRemove" :action="action" list-type="picture-card" :on-success="handleImageSuccess">
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>

  </div>
</template>

<script>
export default {
  name: "SingleImageUpload",
  props: {
    value: {
      type: Array,
      default: "",
    },
    accept: {
      type: String,
      default: "video/*",
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_API + "/index/upload",
      tempUrl: [],
      dataObj: { token: "", key: "" },
      filelist: [],
    };
  },
  computed: {},
  created() {
    if (this.value.length > 0) {
      this.tempUrl = this.value;
      // this.tempUrl = this.value.map((item, index) => {
      //   return { name: index, url: item };
      // });
    }
  },
  methods: {
    emitInput(val) {
      this.$emit("input", this.tempUrl);
    },
    handleImageSuccess(e) {
      let src = "https://performapi.90028.cn" + e.data;
      this.tempUrl.push(src);
      this.emitInput();
    },
    handleRemove(file, fileList) {
      let src = "https://performapi.90028.cn";
      this.tempUrl = fileList.map((item) => {
        if (item.response) {
          return src + item.response.data;
        } else {
          return item.url;
        }
      });
      this.emitInput();
    },
    rmImage(index) {
      this.tempUrl.splice(index, 1);
      this.emitInput();
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadWrapper {
  flex-wrap: wrap;
  align-items: center;
}
video {
  object-fit: fill;
  width: 150px;
  height: 140px;
}
.upload {
  width: 150px;
  height: 140px;
}
.image-preview {
  margin-right: 10px;
  width: 150px;
  height: 140px;
  position: relative;
  border: 1px dashed #d9d9d9;
  float: left;

  .image-preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .image-preview-action {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    cursor: pointer;
    text-align: center;
    line-height: 200px;

    .el-icon-delete {
      font-size: 36px;
    }
  }

  &:hover {
    .image-preview-action {
      opacity: 1;
    }
  }
}
</style>
