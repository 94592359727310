<template>
  <div class="home">
    <div class="PageHd">
      <PageHd @confrimLogin="confrimLogin" bgColor="rgba(255,255,255,0.1)" fontColor="#fff"></PageHd>
    </div>
    <!-- banner -->
    <div class="banner">
      <el-carousel indicator-position="outside">
        <el-carousel-item>
          <img class="bannerIMG" src="@/assets/image/banner.png" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img class="bannerIMG" src="@/assets/image/banner.png" alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img class="bannerIMG" src="@/assets/image/banner.png" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 介紹 -->
    <!--内容 -->
    <div class="boxContent" style="background-attachment: local">
      <div class="search_input flex_aling_justify_center">
        <SearchInput @search="search"></SearchInput>
      </div>
      <!-- 筛选条件 -->
      <div class="marinT30 ">
        <FilterMenu @select="select"></FilterMenu>
      </div>
      <template v-if="list.length>0">
        <!-- 人员  -->
        <template v-if="$store.state.authType == 2">
          <div class="list pointer marinT30" v-infinite-scroll="load" :infinite-scroll-immediate="false" :infinite-scroll-disabled="loadingFlag">
            <!-- @click="jumpRY(item)" -->
            <div class="list_item " v-for="(item,i) in list" :key="i">
              <staffNew :item="item"></staffNew>
            </div>
          </div>
        </template>
        <template v-else>
          <!--  岗位内容  @click="Details(item) -->
          <div class="list  pointer marinT30" v-infinite-scroll="load" :infinite-scroll-immediate="false" :infinite-scroll-disabled="loadingFlag">
            <div class="list_item_new" v-for="(item,i) in list" :key="i">
              <JobRecruiNew :item="item"></JobRecruiNew>
            </div>
          </div>
        </template>
      </template>
      <div class="emptyData" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
      <!-- 曝光 -->
      <!-- 牛马曝光 -->
      <div class="exposure marinT20 flex pointer  " v-if="$store.state.authType == 2&&list1.length>0">
        <div class="exposure_item" @click="jumpRY(item)" v-for="(item,index) in list1" :key="index">
          <img :src="item.member.avatar" alt="">
          <div class="exposure_item_name">
            {{item.real_name}}
          </div>
          <div class="exposure_item_dc">
            视觉年龄：{{item.note.begin_age}}- {{item.note.end_age}}岁
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <PageBt></PageBt>
  </div>
</template>

<script>
import JobRecruiNew from "@/components/JobRecruiNew";
import staffNew from "@/components/staffNew";
import {
  member_list,
  job_list,
  getbanner_list,
  job_new_list,
} from "@/utils/network.js";
export default {
  components: { JobRecruiNew, staffNew },
  data() {
    return {
      aboutInfo: {},
      list: [],
      list1: [],
      keyword: "",
      banner_list: [],
      searchValue: "",
      queryParams: {
        page: 1,
        limit: 10,
      },
      selectObj: {},
      authType: 0,
      loadingFlag: false,
    };
  },
  async created() {
    // banner
    this.getbanner_lists();
    this.int();
    // 曝光
    const res = await member_list({ is_exposure: 2, limit: 5 });
    this.list1 = res.data.res;
  },
  methods: {
    confrimLogin() {
      this.int();
    },
    jumpRY(item) {
      this.$router.push({
        path: "/notesDetials",
        query: { item: JSON.stringify(item) },
      });
    },
    // 职位详情
    Details(item) {
      this.$router.push({
        path: "/positionDetails",
        query: { item: JSON.stringify(item) },
      });
    },
    search(e) {
      this.keyword = e;
      this.int();
    },
    async select(e) {
      this.selectObj = e;
      this.int();
      const res = await member_list({
        ...this.selectObj,
        is_exposure: 2,
        limit: 5,
      });
      this.list1 = res.data.res;
    },
    load() {
      this.queryParams.page++;
      this.getData();
    },
    int() {
      this.list = [];
      this.queryParams.page = 1;
      this.getData();
    },
    async getData() {
      let data = {
        ...this.queryParams,
        ...this.selectObj,
        text: this.keyword,
        city_id: localStorage.getItem("currentCity")
          ? JSON.parse(localStorage.getItem("currentCity")).city_code
          : null,
      };
      let res;
      this.loadingFlag = true;
      if (this.$store.state.authType == 2) {
        res = await member_list(data);
      } else {
        data.is_open = 2;
        res = await job_new_list(data);
      }
      this.loadingFlag = false;
      if (res.data.res.length > 0) {
        this.list = [...this.list, ...res.data.res];
      }
    },
    async getbanner_lists() {
      let res = await getbanner_list({
        type: 1,
      });
      this.banner_list = res.data;
    },
  },
};
</script>


<style   lang="scss" scoped>
.PageHd {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.banner {
  width: 100%;
  max-width: 100vw;
  height: 789.2px;
  // background-color: red;
  .bannerIMG {
    width: 100vw;
    height: 789.2px;
    object-fit: cover !important;
  }
}
::v-deep .el-carousel__container {
  height: 789px !important;
}

.boxContent {
  background: #f4f6f9;
  position: relative;
  padding: 30px 250px;

  // 搜索部分
  .search_input {
    position: relative;
    margin-top: -100px;

    height: 120px;
    background: #ffffff;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.4);
    z-index: 3;
  }
  // 内容部分
  // 内容部分
  .list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: space-between;
    max-height: 600px;
    min-height: 300px;
    .list_item {
      margin-top: 15px;
      width: 49%;
    }
    .list_item_new {
      margin-bottom: 15px;
      width: 100%;
    }
  }
  .list:after {
    content: "";
    width: 33%;
  }
}
// 曝光
.exposure {
  .exposure_item {
    margin-right: 1%;
    width: 19.5%;
    height: 270px;
    background: #ffffff;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      background: #ccdef6;
      border-radius: 20px;
    }
    .exposure_item_name {
      margin-top: 20px;
      font-size: 15px;
      font-weight: 700;
      color: #333333;
    }
    .exposure_item_dc {
      margin-top: 10px;
      font-size: 13px;
      color: #666666;
    }
  }
}
</style>
