<template>
  <div class="search_box  flex_aling_center">
    <div class="search">
      <el-input v-model="input" placeholder="请输入角色名/剧组通告"></el-input>
    </div>
    <div class="search_btn flex_aling_justify_center" @click="search">
      搜索
    </div>
  </div>
</template>

<script >
export default {
  data() {
    return {
      input: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.input);
    },
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  .search {
    width: 932px;
    height: 55px;
    background: #ffffff;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid #ef3e24;
    overflow: hidden;
  }
  .search_btn {
    width: 135px;
    height: 55px;
    background: linear-gradient(90deg, #ef3c24, #ef7a24);
    border-radius: 0px 10px 10px 0px;

    font-size: 18px;
    color: #ffffff;
  }
  ::v-deep .el-input {
    height: 55px !important;
    border: none;
  }
  ::v-deep .el-input__inner {
    height: 55px !important;
    border: none;
  }
}
</style>

